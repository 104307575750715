import useTheme from 'src/customizations/themes/hooks/useTheme';
import { MathFieldRenderer } from './formats/MathField';
export default function useStyles() {
    const [{ theme }] = useTheme();
    return `
    .ql-editor {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      word-spacing: 2px;
      background-color: ${theme.palette.background.main};
      color: ${theme.palette.typography.primary};
      
      /* This is to offset mathlive virtual keyboard, so that the math field isn't covered */
      /*padding-bottom: 400px;*/
      overflow-y: visible;
      height: auto;
    }
      
    .ql-editor[contenteditable="false"] {
      padding-bottom: ${theme.spacing.standard.sm}px;
    }
      
    .ql-editor.ql-blank::before {
      color: ${theme.palette.typography.placeholder};
    }

    @keyframes loader-rotate {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }

    .ql-editor .image-loader-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${theme.palette.backdrop};
      border-radius: ${theme.borderRadius.standard.md}px;
    }
    
    .ql-editor .image-loader-container .image-loader {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border: 4px solid ${theme.palette.background.darker};
      border-top: 4px solid ${theme.palette.primary.dark};
      animation: loader-rotate 1s linear infinite;
    }            

    .ql-editor a {
      color: ${theme.palette.typography.link}
    }

    span:has(> div.${MathFieldRenderer.className}), s:has(> div.${MathFieldRenderer.className}) {
      display: inline-block;
    }

    /* Quill-Better-Table related styles*/

    .quill-better-table, .quill-better-table th, .quill-better-table td {
      border: 1px solid ${theme.palette.typography.primary} !important;
    }

    .qlbt-operation-menu {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 240px !important;
    }

    .qlbt-operation-menu .qlbt-operation-menu-item {
      background-color: ${theme.palette.background.dark};
      color: ${theme.palette.typography.primary};
    }

    .qlbt-operation-menu .qlbt-operation-menu-icon path {
      fill: ${theme.palette.typography.primary};
    }
      
    .qlbt-operation-menu .qlbt-operation-menu-dividing {
      background-color: ${theme.palette.background.darker};
    }
  `;
}
