import { web2RNCom } from '.';
import getModuleInterfaceAPIs from '../common/getModuleAPIs';
export const WebRNModuleAPIs = getModuleInterfaceAPIs({
    DeviceInfo: {
        getInfo: true,
    },
    Logger: {
        error: true,
        log: true,
        warn: true,
    },
    Login: {
        googleSignin: true,
        appleSignin: true,
    },
    Storage: {
        set: true,
        get: true,
    },
    TTS: {
        init: true,
        speak: true,
        pause: true,
        resume: true,
        stop: true,
        getVoices: true,
        getMatchingVoice: true,
        isSupported: true,
    },
    WVLifeCycle: {
        loaded: true,
    },
    FileStorage: {
        getPaths: true,
        joinPaths: true,
        resolve: true,
        unlink: true,
        ls: true,
        exists: true,
        copyFileAssets: true,
        unzip: true,
        downladFile: true,
        getNonExistingFilePath: true,
        openFile: true,
    },
}, web2RNCom);
