/**
 * The below section is to sync the store changes from Web 2 RN
 */
import { WebRNModuleAPIs } from 'src/components/15-containers/SuperChargedWebView/communication/web2RNCom/WebRNModuleAPIs';
import StorageKeys from 'src/constants/storageKeys';
import { getJSONStorage } from 'src/lib/storage';
import { isWebView } from 'src/utils/platform';
function initSync(store, keysToSync) {
    const storage = getJSONStorage(store);
    storage.addOnValueChangedListener(changedKey => {
        if (keysToSync.includes(changedKey)) {
            const jsonValue = storage.get(changedKey);
            WebRNModuleAPIs.Storage.set({ store, key: changedKey, value: jsonValue });
        }
    });
}
const deviceStorageKeysToSync = [StorageKeys.debugSettings];
const sessionStorageKeysToSync = [];
export default function initSyncStorage() {
    if (isWebView) {
        initSync('device', deviceStorageKeysToSync);
        initSync('session', sessionStorageKeysToSync);
    }
}
