import { MMKV } from 'react-native-mmkv';
import { parseJSON } from 'src/utils/json';
class MMKVJSONStorage extends MMKV {
    set(key, value) {
        super.set(key, JSON.stringify(value));
    }
    get(key) {
        const value = super.getString(key);
        return typeof value === 'string' ? parseJSON(value) : value;
    }
}
const deviceStorageOptions = {
    id: 'device',
};
const sessionStorageOptions = {
    id: 'session',
};
export const deviceStorage = new MMKVJSONStorage(deviceStorageOptions);
export const sessionStorage = new MMKVJSONStorage(sessionStorageOptions);
export const getJSONStorage = (store) => store === 'device' ? deviceStorage : sessionStorage;
const deviceStorageNative = new MMKV(deviceStorageOptions);
const sessionStorageNative = new MMKV(sessionStorageOptions);
export const getStorage = (store) => store === 'device' ? deviceStorageNative : sessionStorageNative;
export const UNSAFE_clearAllStorages = () => {
    deviceStorage.clearAll();
    sessionStorage.clearAll();
};
