import config from 'react-native-ultimate-config';
import debugSettingsStorage from './storage/settings';
import { IDebugEnv } from './types';
export const getEnv = (() => {
    const debugSettings = debugSettingsStorage.getSettings();
    return () => {
        const env = __DEV__ ? IDebugEnv.DEV : IDebugEnv.PROD;
        // const env = __DEV__ ? debugSettings.env : IDebugEnv.PROD;
        return env;
    };
})();
export const getServerURL = (() => {
    const serverURLs = {
        [IDebugEnv.DEV]: 'DEV_SERVER_URL',
        [IDebugEnv.PRE_PROD]: 'PRE_PROD_SERVER_URL',
        [IDebugEnv.PROD]: 'PROD_SERVER_URL',
    };
    return () => {
        const env = getEnv();
        return config[serverURLs[env]];
    };
})();
