import { ttsPreferencesStorage } from '../storage';
import isSSML from '../utils/isSSML';
import { isWebView } from 'src/utils/platform';
import { WebRNModuleAPIs } from 'src/components/15-containers/SuperChargedWebView/communication/web2RNCom/WebRNModuleAPIs';
const TTS = isWebView
    ? WebRNModuleAPIs.TTS
    : {
        async init() { },
        async speak(text, options = {}) {
            const utterance = new SpeechSynthesisUtterance();
            const { voiceId = ttsPreferencesStorage.getVoiceId(), rate = ttsPreferencesStorage.getRate(), hooks, } = options;
            if (voiceId) {
                const voice = await this.getMatchingVoice(voiceId);
                if (voice) {
                    utterance.voice = voice?.voice;
                }
            }
            // Since SSML text would already contain the `rate`, we can safely ignore
            // the rate parameter and set a dummy rate
            if (!isSSML(text) && rate && rate > 0) {
                utterance.rate = rate;
            }
            utterance.text = text;
            utterance.addEventListener('end', () => {
                hooks?.onEnd?.();
            });
            utterance.addEventListener('error', () => {
                hooks?.onSkip?.();
            });
            // utterance.addEventListener('pause', () => {
            //   console.log('Utterance Pause');
            // });
            // utterance.addEventListener('resume', () => {
            //   console.log('Utterance Resume');
            // });
            // utterance.addEventListener('start', () => {
            //   console.log('Utterance Start');
            // });
            speechSynthesis.speak(utterance);
        },
        async pause() {
            speechSynthesis.pause();
        },
        async resume() {
            speechSynthesis.resume();
        },
        async stop() {
            speechSynthesis.cancel();
        },
        async getVoices() {
            const voices = await new Promise(resolve => {
                const timer = setInterval(() => {
                    const _voices = speechSynthesis.getVoices();
                    if (_voices.length > 0) {
                        resolve(_voices);
                        clearInterval(timer);
                    }
                }, 50);
            });
            return voices
                .filter(voice => voice.lang.startsWith('en'))
                .map((voice) => ({
                language: voice.lang,
                name: voice.name,
                id: voice.voiceURI,
                voice,
            }));
        },
        async getMatchingVoice(voiceId) {
            const voices = await this.getVoices();
            return voices.find(voice => voice.id === voiceId);
        },
        async isSupported() {
            return 'speechSynthesis' in window;
        },
    };
export default TTS;
