import { useEffect } from 'react';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleSigninWebAPI, useGoogleSigninAPI } from '../apis/signin';
import { getDeviceInfo } from 'src/lib/device';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { WebRNModuleAPIs } from 'src/components/15-containers/SuperChargedWebView/communication/web2RNCom/WebRNModuleAPIs';
import { statusCodes } from '@react-native-google-signin/google-signin';
import { isWebView } from 'src/utils/platform';
function useWVSignin(events = {}) {
    const { translate } = useI18n();
    const [signinAPIState, signinAPIActions] = useGoogleSigninAPI(events);
    const handleSignin = useCallbackWithCtx(async (ctx) => {
        try {
            const userInfo = await WebRNModuleAPIs.Login.googleSignin();
            ctx.signinAPIActions.submit(userInfo.idToken, await getDeviceInfo());
        }
        catch (error) {
            switch (error.code) {
                case statusCodes.SIGN_IN_CANCELLED:
                    // user cancelled the login flow
                    // So do nothing
                    break;
                default:
                    console.log('error:', error);
                    snackbarHelperActions.show({
                        message: error?.message ||
                            translate('Something went wrong, please try again'),
                        variant: 'NEGATIVE',
                    });
            }
        }
    }, { signinAPIActions });
    return [signinAPIState, handleSignin];
}
function useWebSignin(events = {}) {
    const { translate } = useI18n();
    const [signinAPIState, signinAPIActions] = useGoogleSigninWebAPI(events);
    const handleSignin = useGoogleLogin({
        flow: 'implicit',
        async onSuccess(tokenResponse) {
            signinAPIActions.submit(tokenResponse.access_token, await getDeviceInfo());
        },
        onError(errorResponse) {
            console.log('Login Failed:', errorResponse);
            snackbarHelperActions.show({
                message: errorResponse?.error_description ||
                    translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        },
    });
    return [signinAPIState, handleSignin];
}
export default function useGoogleSignin(events = {}) {
    const { translate } = useI18n();
    const [wvSigninAPIState, handleWVSignin] = useWVSignin(events);
    const [webSigninAPIState, handleWebSignin] = useWebSignin(events);
    useEffect(() => {
        const error = wvSigninAPIState.error || webSigninAPIState.error;
        if (error) {
            snackbarHelperActions.show({
                message: error.code === 'ERR_BAD_RESPONSE'
                    ? translate('Network connection failure. Please try later')
                    : error.message ||
                        translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        }
    }, [wvSigninAPIState.error, webSigninAPIState.error]);
    const state = {
        googleSigninAPI: isWebView ? wvSigninAPIState : webSigninAPIState,
    };
    const actions = {
        signinWithGoogle: isWebView ? handleWVSignin : handleWebSignin,
    };
    return [state, actions];
}
